import React, {Component, Fragment} from 'react';
import withPopupComponents from "../hoc/withPopupComponents";
import {withTranslation} from "react-i18next";
import withPopupDispatch from "../hoc/withPopupDispatch";
import SContainer from "../SContainer";
import Footer from "../Footer";
import Form from "../Form";
import BenefitCodeInput from "../BenefitCodeInput";
import Button from "../Button";
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from "../../utils/errorConstant";
import {TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION, TYPE_PRODUCT_SUBSCRIPTION} from "../../utils/globalConstants";
import {URL_BENEFITS_APPLY_COUPON, URL_BENEFITS_GET_BENEFITS_API, URL_BENEFITS_GET_COUPON_APPLICABLE_API, URL_BENEFITS_REGISTER_BENEFIT} from "../../utils/urlConstant";
import {formatDpiDate} from "../../utils/simpleDateUtils";
import {errorCode2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

// See web/benefits response in DPI_API doc
const TYPE_COUPON_FLAT_AMOUNT = "1";
const TYPE_COUPON_FLAT_RATE = "2";
const TYPE_COUPON_FREE = "3";
const TYPE_COUPON_FREE_TRIAL = "4";

@autobind
class BenefitAddPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputBenefitCode: "",

      currentBenefit: null,
      currentCouponApplicable: null,

      benefitData: null,
    };
  }

  fetchCouponApplicable(payload, successCallback) {
    this.props.showProgressBar();
    httpClient
        .post(URL_BENEFITS_GET_COUPON_APPLICABLE_API, payload)
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          if (successCallback) {
            successCallback(response);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  handleCouponApplyClick(e, payload, successCallback) {
    e.preventDefault();

    this.props.showProgressBar();
    httpClient
        .post(URL_BENEFITS_APPLY_COUPON, payload)
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          if (successCallback) {
            successCallback(response);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  handleListClick(e, benefitDetail) {
    e.preventDefault();

    // Set benefitId. If same, it means user clicked the opened one. Make it null
    let userClickedBenefit = this.state.currentBenefit !== benefitDetail ? benefitDetail : null;
    this.setState(() => ({currentBenefit: userClickedBenefit}));
    // fetch coupon applicable if not closing
    const isClosing = userClickedBenefit === null;
    const productType = benefitDetail.productDetail.productType;
    const isProductSubscription
        = productType === TYPE_PRODUCT_SUBSCRIPTION
        || productType === TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION;

    if (!isClosing && this.props.isOrdered && isProductSubscription) {
      this.fetchCouponApplicable({couponId: benefitDetail.couponId},
          (response) => this.setState({currentCouponApplicable: response.data}));
    }
  }

  renderBenefit(benefitDetail) {
    let couponTypeText;
    switch (benefitDetail.couponType) {
      case TYPE_COUPON_FLAT_AMOUNT:
        couponTypeText = this.props.t('TV_SID_BILIING_MIX_DISCOUNT', {amount: benefitDetail.v_couponAmount});
        break;
      case TYPE_COUPON_FLAT_RATE:
        couponTypeText = this.props.t('TV_SID_BILIING_MIX_PERCENT_DISCOUNT', {rate: benefitDetail.couponRate});
        break;
      case TYPE_COUPON_FREE:
        couponTypeText = this.props.t('COM_SID_FREE_M_FREE');
        break;
      case TYPE_COUPON_FREE_TRIAL:
        couponTypeText = this.props.t('TV_SID_BILLING_FREE_TRIAL');
        break;
      default:
        throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    let couponButtonContent;
    const productType = benefitDetail.productDetail.productType;
    const isProductSubscription = productType === TYPE_PRODUCT_SUBSCRIPTION || productType === TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION;
    if (this.state.currentCouponApplicable && isProductSubscription) {
      const {subscribeYN, applyYN} = this.state.currentCouponApplicable;
      if (subscribeYN === "Y" && applyYN === "Y") {
        couponButtonContent =
            <Fragment>
              <p className={`s-accordion-des`}>
                {this.props.t('TV_SID_PAYMENT_USE_THIS_COUPON_IMMEDIATELY_MSG')}
              </p>
              <div className={`s-btn-wrap`}>
                <button type={`button`}
                        className={`s-btn s-btn-default`}
                        onClick={(e) => {
                          this.handleCouponApplyClick(e, {couponId: benefitDetail.couponId}, () => {
                            this.props.showPopup2Line(this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_80'), null, () => {
                              this.props.hideAnyPopup();
                              this.triggerLoadData();
                            });
                          });
                        }}>
                  {this.props.t('COM_IDWS_MOIP_APPLY')}
                </button>
              </div>
            </Fragment>;
      } else if (subscribeYN === "Y" && applyYN === "N") {
        couponButtonContent =
            <p className={`s-accordion-des`}>
              {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_81')}
            </p>;
      } else if (subscribeYN === "E" && applyYN === "Y") {
        couponButtonContent =
            <p className={`s-accordion-des`}>
              {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_83')}
            </p>;
      } else if (subscribeYN === "C" && applyYN === "Y") {
        couponButtonContent =
            <p className={`s-accordion-des`}>
              {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_82')}
            </p>;
      } else {
        couponButtonContent =
            <p className={`s-accordion-des`}>
              {this.props.t('TV_SID_PAYMENT_NOT_SUBSCRIBER_SERVICE_COUPON')}
            </p>;
      }
    }

    return (
        <li key={benefitDetail.couponId}
            className={this.state.currentBenefit === benefitDetail ? "s-current" : null}>
          <a className={`s-panel-overview`} onClick={(e) => {
            this.handleListClick(e, benefitDetail)
          }}>
            <h3 className={`s-item-title`}>{benefitDetail.couponName}</h3>
            <div className={`s-info`}>
              <span>{formatDpiDate(benefitDetail.useValidEndDate, "/", "MMDDYY", true)}</span>
            </div>
            <img src={benefitDetail.productDetail.appIconUrl} alt={benefitDetail.couponName}/>
            <span className={`s-panel-arrow`}><span>view</span></span>
          </a>
          <div className={`s-accordion-cont`}>
            <h4 className={`s-accordion-title`}>{this.props.t('TV_SID_PAYMENT_AVAILABLE_SERVICE') + ": " + benefitDetail.productDetail.appName}</h4>
            <h4 className={`s-accordion-title`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_77') + ' ' + this.props.t('COM_TV_SID_TYPE_KR_KIND') + ": " + couponTypeText}</h4>
            <div>{couponButtonContent}</div>
          </div>
        </li>
    );
  }

  renderBenefitList() {
    const {couponDetails, couponCount} = this.state.benefitData;
    const couponCountInt = parseInt(couponCount, 10);

    let ulContent;
    if (couponCountInt > 0) {
      ulContent = couponDetails.map((couponDetail) => this.renderBenefit(couponDetail));
    } else if (couponCountInt === 0) {
      ulContent =
          <li>
            <div className={`s-none-panel`}>
              <span className={`s-icon-caution`}/>
              <p>{this.props.t('TV_SID_PAYMENT_NO_COUPON_AVAIL')}</p>
            </div>
          </li>;
    } else {
      ulContent = (
          <li>
            <div className={`s-none-panel`}>
              <span className={`s-icon-caution`}/>
              <p>{this.props.t('COM_FAILED_FETCH_LIST')}</p>
            </div>
          </li>
      );
    }
    return (
        <ul className={`s-img-panel s-type-accordion`}>
          {ulContent}
        </ul>
    );
  }

  fetchBenefits(successCallback) {
    this.props.showProgressBar();
    httpClient
        .get(URL_BENEFITS_GET_BENEFITS_API)
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          if (successCallback) {
            successCallback(response);
          }
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  setCurrentBenefitAndBenefitData(data) {
    //
    // when click the menu, refresh the data and pick the first one.
    // (except when the click event was from "view expired button")
    //
    this.setState({
      currentBenefit: data.couponCount > 0 ? data.couponDetails[0] : null,
      benefitData: data,
    });

    //
    // if coupon, set currentCouponApplicable, after fetch
    //
    if (data.couponCount > 0) {
      const currentBenefit = data.couponDetails[0];
      const productType = currentBenefit.productDetail.productType;
      const isProductSubscription = productType === TYPE_PRODUCT_SUBSCRIPTION || productType === TYPE_PRODUCT_FREE_TRIAL_SUBSCRIPTION;
      if (this.props.isOrdered && isProductSubscription) {
        this.fetchCouponApplicable({couponId: currentBenefit.couponId},
            (response) => this.setState({currentCouponApplicable: response.data}));
      }
    }
  }

  triggerLoadData() {
    this.fetchBenefits((response) => this.setCurrentBenefitAndBenefitData(response.data));
  }

  handleRedeemButtonClick(e) {
    e.preventDefault();

    this.props.showProgressBar();
    httpClient
        .post(URL_BENEFITS_REGISTER_BENEFIT, {
          couponId: this.state.inputBenefitCode
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          // show popup by benefit type
          let popupText;
          if (!this.props.isOrdered) {
            popupText = this.props.t('TV_SID_BILIING_COUPON_REDEEMED_SUCCESFULLY');
          } else if (this.props.isOrdered) {
            popupText = this.props.t('TV_SID_BILIING_COUPON_WAS_REDEEMED_ENJOY');
          } else {
            throw new Error(MSG_SHOULD_NEVER_HAPPEN);
          }

          // Do below after popup Ok button
          // - clear input
          // - reset data
          this.props.showPopup2Line(popupText, null, () => {
            this.setState({inputBenefitCode: ""});
            this.setCurrentBenefitAndBenefitData(response.data);
            this.props.hideAnyPopup();
          });
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  handleInputChange(e) {
    e.preventDefault();

    const newValue = e.target.value.replace(/[^a-zA-Z0-9#]/g, "").toUpperCase();
    this.setState({
      inputBenefitCode: newValue
    });
  }

  render() {
    const {benefitData, inputBenefitCode} = this.state;

    let couponCount = "-";
    if (benefitData && benefitData.couponCount > 0) {
      couponCount = benefitData.couponCount;
    }

    return (
        <PageContainer
            className={`s-wrap s-is-navi`}
            showNav={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            dimAllNavMenusButBenefit={!this.props.isOrdered}>
          <SContainer>
            <Form>
              <div className={`s-addcode-section`}>
                <div className={`container`}>
                  <div className={'row'}>
                    <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3`}>
                      <BenefitCodeInput
                          name={`benefitCode`}
                          value={inputBenefitCode}
                          ssoAccountId={this.props.ssoAccountId}
                          onChange={this.handleInputChange}/>
                      <Button
                          disabled={inputBenefitCode.length <= 9}
                          className={`one_btn_wrapping formM`}
                          value={this.props.t('TV_SID_REDEEM_CUPON_KR_REG')}
                          onClick={this.handleRedeemButtonClick}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`container`}>
                <div className={'row'}>
                  <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 s-xs-offset0`}>
                    <ul className={`s-tab alone`}>
                      <li className={"s-current"}>
                        <a>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_77') + " (" + couponCount + ")"}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`s-tabcontent s-current`}>
                  <div className={'row'}>
                    <div className={`col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 s-xs-offset0`}>
                      {benefitData &&
                      this.renderBenefitList()
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.triggerLoadData();
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(BenefitAddPage)));
