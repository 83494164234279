import React, {Component} from 'react';
import SContainer from '../SContainer';
import {withTranslation} from 'react-i18next';
import {URL_AUTH_SIGN_IN_PAGE, URL_PAYMETHODS_DEFAULT_CARD_API, URL_PAYMETHODS_PAGE} from '../../utils/urlConstant';
import Footer from '../Footer';
import {
    PAYMETHOD_GCC,
    PAYMETHOD_KCC,
    PAYMETHOD_TOSS,
    SAMSUNG_CHECKOUT,
    TYPE_PAYMENT,
    TYPE_PAYMETHODS,
    TYPE_PAYON,
    TYPE_PAYPALON,
    TYPE_SIGNON
} from '../../utils/globalConstants';
import {ERR_CODE_SUCCESS, MSG_SHOULD_NEVER_HAPPEN} from '../../utils/errorConstant';
import {getCardByType} from "../../utils/cardUtils";
import {autobind} from 'core-decorators';
import PageContainer from '../PageContainer';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import withPopupDispatch from '../hoc/withPopupDispatch';
import withPopupComponents from '../hoc/withPopupComponents';
import {getParams} from '../../utils/queryUtil';

@autobind
class SuccessPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDefaultCard: false
    }
  }

  setButtonTag() {
    if (this.props.type === TYPE_SIGNON) {
      return <></>;
    }

    let href;
    if (this.props.type === TYPE_PAYMETHODS) {
      href = this.props.returnUrl ? this.props.returnUrl : URL_PAYMETHODS_PAGE;
    } else if (this.props.type === TYPE_PAYON || this.props.type === TYPE_PAYMENT || this.props.type === TYPE_PAYPALON) {
      href = URL_AUTH_SIGN_IN_PAGE;
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }

    const doOnClick = () => {
      const cardId = getParams(window.location.search).cardId;
      if(this.state.isDefaultCard) {
        this.props.showProgressBar();
        httpClient
            .post(URL_PAYMETHODS_DEFAULT_CARD_API, {cardId: cardId})
            .then(response => {
              if(response.data.status === ERR_CODE_SUCCESS) {
                window.open(URL_PAYMETHODS_PAGE, '_self');
              } else {
                let msg = response.data.result ? response.data.result : this.props.t('TV_SID_PAYMENT_MIX_UNABLE_REQUEST_COTACT');
                this.props.showPopupLongLine(msg);
              }
            })
            .catch((error) => defaultErrorHandler(this.props, error));
      } else {
        window.open(URL_PAYMETHODS_PAGE, "_self");
      }
    }

    return (
        <div className={`btn_wrapping`}>
            <button className={`s-btn-default`} onClick={doOnClick}>{this.props.t('COM_SID_OK')}</button>
        </div>
    );
  }

  setMessageTag() {
    if (this.props.type === TYPE_PAYMENT) {
      return (
          <>
            <div className={`msg_continue`}>{this.props.t('TV_SID_BILLING_CBOCT_PAYING_FREE_TRIAL_COUPON_MSG')}</div>
            <div className={`msg_continue`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_23')}</div>
          </>
      );
    }

    if (this.props.type === TYPE_PAYON
        || (this.props.type === TYPE_PAYMETHODS
            && this.props.paymethod === PAYMETHOD_GCC
            && this.props.authCode)) {
      return (
          <>
            <div className={`msg_continue`}>{this.props.t('TV_SID_BILLING_CBAUG_INFO_MSG_SAMLL_AMOUNT_AVAIL')}</div>
            <div className={`msg_continue`}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_23')}</div>
          </>
      );
    }

    if (this.props.type === TYPE_PAYMETHODS || this.props.type === TYPE_SIGNON) {
      return <div className={`msg_continue`}>{this.props.t('TV_SID_BILLING_CBAUG_INFO_MSG_SAMLL_AMOUNT_AVAIL')}</div>;
    }

    return <></>;
  }

  setSuccessDesc() {
    if (this.props.type === TYPE_PAYPALON) {
      return (
          <div className={`msg`}>
            <p>
              <strong>Completed!</strong>
              <span className={`token_info`}>{this.props.paypalId}</span>
              {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_22')}
            </p>
          </div>
      );
    } else if (this.props.type === TYPE_PAYMENT) {
      return (
          <div className={`msg`}>
            <p>
              <strong>{this.props.t('COM_TV_SID_BILLING_42682_MSG_14')}</strong>
            </p>
          </div>
      );
    } else if (this.props.paymethod === PAYMETHOD_GCC || this.props.paymethod === PAYMETHOD_KCC || this.props.paymethod === PAYMETHOD_TOSS) { // && (TYPE_PAYON || TYPE_SIGNON || TYPE_PAYMETHODS)
      const card = getCardByType(this.props.cardType);
      const cardName = card ? this.props.t(card.key) : "";
      const lastCardNum = this.props.lastCardNum ? this.props.lastCardNum : "xxxx";

      // only GCC and Toss card that support default card
      const isSupportDefaultCard =
          this.props.paymethod === PAYMETHOD_GCC || this.props.paymethod === PAYMETHOD_TOSS;

      const changeDefaultCard = ()=>{
        this.setState((prevState) => ({isDefaultCard: !prevState.isDefaultCard}))
      }

      return (
          <div className={`msg`}>
            <p>
              <strong>Completed!</strong>
              <span className={`token_info`}>{cardName + " X-" + lastCardNum}</span>
              {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_22')}
            </p>
            {
                isSupportDefaultCard &&
                <div className={"s-checkbox"} style={{marginTop: "25px"}}>
                  <input type={"checkbox"} onClick={changeDefaultCard} checked={this.state.isDefaultCard}/>
                  <label onClick={changeDefaultCard} className={"blueText"}>{this.props.t('COM_SID_BILLING_WEB_CEAUG_SET_AS_DEFAULT_PAYMENT_METHOD')}</label>
                </div>
            }
          </div>
      );
    } else {
      throw new Error(MSG_SHOULD_NEVER_HAPPEN);
    }
  }

  render() {
    const mainDivClassName = this.props.type === TYPE_PAYMENT ? "completed_payment" : "completed_add_token";

    return (
        <PageContainer
            hidden={true}
            showNav={this.props.type === TYPE_PAYMETHODS}
            showSubHeader={true}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.type === TYPE_PAYMETHODS ? this.props.t('COM_SID_CREDIT_DEBIT_CARD') : SAMSUNG_CHECKOUT}>
          <SContainer>
            <div className={`container-fluid s-sc-inner`}>
              <div className={`row`}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={mainDivClassName}>
                    {this.setSuccessDesc()}
                    {this.setMessageTag()}
                    {this.setButtonTag()}
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(SuccessPage)));
