import React, {Component} from 'react';
import withPopupComponents from "../hoc/withPopupComponents";
import {withTranslation} from "react-i18next";
import withPopupDispatch from "../hoc/withPopupDispatch";
import SContainer from "../SContainer";
import EmailAddressInput from "../EmailAddressInput";
import Button from "../Button";
import Footer from "../Footer";
import {isEmail} from "../../utils/emailUtils";
import {URL_CUSTOMERS_CHANGE_BUYER_EMAIL_API, URL_CUSTOMERS_GET_BUYER_EMAIL_API} from "../../utils/urlConstant";
import {ERR_CODE_SUCCESS} from "../../utils/errorConstant";
import {errorCode2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

@autobind
class CustomerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalInputValue: "",
      inputValue: "",
      inputValid: false,
      inputDisabled: true,
      buttonDisabled: false,
      token: "",
    };
  }

  changeBuyerEmailAddr(newEmailAddr) {
    this.props.showProgressBar();
    httpClient
        .post(URL_CUSTOMERS_CHANGE_BUYER_EMAIL_API, {
          eMail: newEmailAddr
        }, { headers: {
          'X-CSRF-TOKEN': this.state.token
        }})
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.setState({
            inputDisabled: true,
            buttonDisabled: false,
            originalInputValue: newEmailAddr
          });
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  handleClick(e) {
    e.preventDefault();
    if (this.state.inputDisabled) { // let user change email address
      this.setState({
        inputDisabled: false,
        buttonDisabled: true
      });
    } else { // call ajax to change the email
      this.changeBuyerEmailAddr(this.state.inputValue);
    }
  }

  handleChange(e) {
    e.preventDefault();

    let newValue = e.target.value;
    let isInputValid = isEmail(newValue) && newValue !== this.state.originalInputValue;

    this.setState( () => ({
      inputValue: newValue,
      inputValid: isInputValid,
      buttonDisabled: !isInputValid
    }));
  }

  fetchBuyerEmailAddr() {
    this.props.showProgressBar();
    httpClient
        .get(URL_CUSTOMERS_GET_BUYER_EMAIL_API)
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          const buyerEmailAddress = response.data.buyerEmailAddress;
          this.setState({
            originalInputValue: buyerEmailAddress,
            inputValue: buyerEmailAddress,
            token: response.data.token,
          })
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  render() {
    return (
        <PageContainer
            className={`s-wrap s-is-navi`}
            showNav={true}
            url={this.props.url}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}>
          <SContainer>
            <div className={`container`}>
              <div className={'row'}>
                <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                  <div className={`s-section`}>
                    <EmailAddressInput
                        name={`email`}
                        value={this.state.inputValue}
                        isValid={this.state.inputValid}
                        disabled={this.state.inputDisabled}
                        onChange={this.handleChange}/>
                    <Button
                        disabled={this.state.buttonDisabled}
                        className={`one_btn_wrapping formM`}
                        value={this.state.inputDisabled
                            ? this.props.t('COM_BUTTON_EDIT')
                            : this.props.t('SID_SAVE')}
                        onClick={this.handleClick}/>
                  </div>
                </div>
              </div>
            </div>
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.fetchBuyerEmailAddr();
  }

}

export default withPopupComponents(withTranslation()(withPopupDispatch(CustomerPage)));
