import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Footer from '../Footer';
import SContainer from '../SContainer';
import {EMAIL_CHECKOUT_CS} from '../../utils/globalConstants';
import EmailInput from '../EmailInput';
import Select from '../Select';
import Button from '../Button';
import {URL_ORDERS_GET_INVOICES_API, URL_SUPPORT_SEND_EMAIL_API} from '../../utils/urlConstant';
import {ERR_CODE_SUCCESS} from '../../utils/errorConstant';
import Form from '../Form';
import withPopupComponents from '../hoc/withPopupComponents';
import withPopupDispatch from '../hoc/withPopupDispatch';
import {getParams} from "../../utils/queryUtil";
import {isEmail} from "../../utils/emailUtils";
import {errorCode2Msg} from "../../translations/i18n-helper";
import {autobind} from 'core-decorators';
import httpClient, {defaultErrorHandler} from '../../utils/httpClient';
import PageContainer from '../PageContainer';

const DEFAULT_REASON_VALUE = 0;

// See DPI API send/email for these "category value"
const CATEGORY_CHECKOUT_SETTINGS = "1";
const CATEGORY_PAYMETHOD = "2";
const CATEGORY_HISTORY = "3";
const CATEGORY_PROMOTION = "4";
const CATEGORY_CANCEL = "5";
const CATEGORY_REFUND = "6";
const CATEGORY_ETC = "7";
const CATEGORY_ERROR = "8";

// Same as Constant in SupportServiceImpl class
export const TO_PM = "01";
export const TO_CP = "02";

// See send/mail in DPI_API doc
export const FROM_ORDER_DETAILS = "18";
export const FROM_SUBSCRIPTION_DETAILS = "20";
export const FROM_SIGN_IN = "21";

@autobind
class HelpEmailPage extends Component {
  constructor(props) {
    super(props);

    const queryString = getParams(window.location.search);

    this.state = {
      isFromEmailValid: isEmail(this.props.buyerEmailAddr),
      fromEmailValue: this.props.buyerEmailAddr || "",
      toEmailValue: null,

      isReasonValid: false,
      reasonSelectValue: DEFAULT_REASON_VALUE,
      reasonTextValue: "",

      invoiceId: queryString.invoiceId,
      viewType: queryString.viewType,
      sendType: queryString.sendType || TO_PM,
      templateType: queryString.templateType || FROM_SIGN_IN
    };
  }

  fetchData() {
    if (!this.state.invoiceId || this.state.sendType === TO_PM) {
      this.setState({toEmailValue: EMAIL_CHECKOUT_CS});
    } else {
      this.props.showProgressBar();

      httpClient
          .post(URL_ORDERS_GET_INVOICES_API, {
            invoiceId: this.state.invoiceId,
            viewType: this.state.viewType
          })
          .then(response => {
            this.props.hideAnyPopup();
            if (response.data.status !== ERR_CODE_SUCCESS) {
              this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
              return;
            }

            if (response.data.invoiceDetails) {
              this.setState({toEmailValue: response.data.invoiceDetails[0].appSupportEmail});
            } else if (response.data.subscriptionInfo) {
              this.setState({subscriptionDetails: response.data.subscriptionInfo[0].appSupportEmail});
            }
          })
          .catch((error) => defaultErrorHandler(this.props, error));
    }
  }

  handleSuccessCallback() {
    this.props.hideAnyPopup();
    window.history.back();
  }

  submitForm(e) {
    e.preventDefault();

    this.props.showProgressBar();

    httpClient
        .post(URL_SUPPORT_SEND_EMAIL_API, {
          buyerEmail: this.state.fromEmailValue,
          category: this.state.reasonSelectValue,
          bodyText: this.state.reasonTextValue,
          sendType: this.state.sendType,
          templateType: this.state.templateType,
          invoiceId: this.state.invoiceId,
          viewType: this.state.viewType
        })
        .then(response => {
          this.props.hideAnyPopup();
          if (response.data.status !== ERR_CODE_SUCCESS) {
            this.props.showPopup2Line(errorCode2Msg(this.props.t, response.data.status));
            return;
          }

          this.props.showPopup2Line(this.props.t('COM_BDP_SID_SUCCESSFULLY_SENT_KR_TRANS'));
          setTimeout(this.handleSuccessCallback, 3000);
        })
        .catch((error) => defaultErrorHandler(this.props, error));
  }

  isReasonValid(select, text) {
    return select !== DEFAULT_REASON_VALUE && text.length >= 1;
  }

  removeEmojis(string) {
    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, '');
  }

  handleReasonTextChange(e) {
    e.preventDefault();

    let newValue = this.removeEmojis(e.target.value);
    let isValid = this.isReasonValid(this.state.reasonSelectValue, newValue);
    this.setState(() => ({reasonTextValue: newValue, isReasonValid: isValid}));
  }

  handleReasonSelectChange(newValue) {
    let isValid = this.isReasonValid(newValue, this.state.reasonTextValue);
    this.setState( () => ({reasonSelectValue: newValue, isReasonValid: isValid}));
  }

  handleFromEmailInputChange(e) {
    e.preventDefault();

    let newValue = e.target.value;
    let isValid = isEmail(newValue);
    this.setState({fromEmailValue: newValue, isFromEmailValid: isValid});
  }

  renderContainerContent() {
    return (
        <Form method={'post'}>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4`}>
                <div className={`s-section`}>
                  <div className={`s-info-group`}>
                    <h3 className={`s-info-title`}>
                      {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_66')}
                    </h3>
                    <p className={`s-info-cont`}>{this.state.toEmailValue}</p>
                  </div>
                </div>
                <div className={`s-section s-topline-section s-form-changeactive`}>
                  <EmailInput
                      name={`buyerEmail`}
                      value={this.state.fromEmailValue}
                      isValid={this.state.isFromEmailValid}
                      onChange={this.handleFromEmailInputChange}/>
                  <div className={`s-form-groupwrap`}>
                    <div className={`form-group`}>
                      <h3 className={`s-form-title`}>
                        {this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_68')}
                      </h3>
                      <Select
                          name={`category`}
                          value={this.state.reasonSelectValue}
                          onChange={this.handleReasonSelectChange}
                          defaultLabel={this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_69')}>
                        <option value={DEFAULT_REASON_VALUE}>{"(" + this.props.t('TV_SID_BILLING_CAJUL_UNCATEGORIZED') + ")"}</option>
                        <option value={CATEGORY_CHECKOUT_SETTINGS}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_10')}</option>
                        <option value={CATEGORY_PAYMETHOD}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_8')}</option>
                        <option value={CATEGORY_HISTORY}>{this.props.t('COM_SID_PURCHASE_HISTORY_KR_MOBILE')}</option>
                        <option value={CATEGORY_PROMOTION}>{this.props.t('COM_PROMOTION_KR_PROMOTION')}</option>
                        <option value={CATEGORY_CANCEL}>{this.props.t('SID_CANCEL')}</option>
                        <option value={CATEGORY_REFUND}>{this.props.t('TV_SID_BILLING_REFUND')}</option>
                        <option value={CATEGORY_ETC}>{this.props.t('TV_SID_PYMENT_WEBPORTAL_42704_STRING_55')}</option>
                        <option value={CATEGORY_ERROR}>{this.props.t('TV_SID_BILLING_CAJUL_ERROR')}</option>
                      </Select>
                    </div>
                    <div className={`form-group`}>
                      <div className={`s-textarea-box`}>
                        <textarea name={`bodyText`} maxLength={`10000`} value={this.state.reasonTextValue} onChange={this.handleReasonTextChange}/>
                      </div>
                    </div>
                    <Button
                        disabled={!(this.state.isReasonValid && this.state.isFromEmailValid)}
                        className={`one_btn_wrapping formM`}
                        value={this.props.t('COM_SID_SEND_KR_TRANSFER')}
                        onClick={this.submitForm}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
    );
  }

  render() {
    return (
        <PageContainer
            hidden={true}
            showNav={!!this.props.authenticated}
            showSubHeader={true}
            ssoAccountId={this.props.ssoAccountId}
            authenticated={this.props.authenticated}
            subHeaderText={this.props.t('COM_SID_HELP')}
            dimAllNavMenusButBenefit={this.props.dimAllNavMenusButBenefit}>
          <SContainer>
            {this.renderContainerContent()}
          </SContainer>
          <Footer/>
        </PageContainer>
    );
  }

  componentDidMount() {
    this.fetchData();
  }
}

export default withPopupComponents(withTranslation()(withPopupDispatch(HelpEmailPage)));
